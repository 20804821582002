import React, { HTMLProps } from "react";
import { css } from "@emotion/react";
import classNames from "classnames";

export type ButtonVariant =
  | "is-primary"
  | "is-danger"
  | "is-success"
  | "is-info"
  | "is-link"
  | "is-text"
  | "is-warning";

export interface ButtonProps
  extends Pick<HTMLProps<HTMLButtonElement>, "onClick" | "disabled"> {
  children: any;
  variant?: ButtonVariant;
  loading?: boolean;
  rounded?: boolean;
  small?: boolean;
  title?: string;
  className?: string;
  type?: "button" | "submit";
}

function Button({ loading, rounded, small, ...props }: ButtonProps) {
  return (
    <button
      type={"button"}
      {...props}
      className={classNames(
        "button",
        props.variant,
        {
          "is-loading": loading,
          "is-rounded": rounded,
          "is-small": small,
        },
        props.className
      )}
    />
  );
}

export const unstyledButtonCss = css`
  border: none;
  background-color: unset;
  cursor: pointer;
  font-size: 100%;
  :hover {
    opacity: 0.7;
  }
  :focus {
    outline: none;
  }
`;

export default Button;
