import { ChangeEvent, Dispatch, useMemo, useState } from "react";

export function useBindState<T = string>(initial?: T) {
  const [state, setState] = useState<T>(initial);
  return useMemo(() => {
    return {
      binders: createBinders<T>(state, setState),
      value: state,
      setValue: setState,
    };
  }, [state]);
}

export function createBinders<T = string>(state: T, setState: Dispatch<T>) {
  return {
    value: state,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value as unknown as T;
      setState(value);
    },
  };
}
