import React from "react";
import { css, Global } from "@emotion/react";
import BaseLayout from "~/layouts/BaseLayout";

interface BlankLayoutProps {
  children: any;
}

const resetCss = css`
  body,
  html {
    background-color: white;
  }
`;

export function BlankLayout(props: BlankLayoutProps) {
  return (
    <BaseLayout>
      <Global styles={resetCss} />
      {props.children}
    </BaseLayout>
  );
}
